import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Revo Brasil - Página não encontrada" />
    <div className="fdb-block">
      <div className="container">
        <div className="col-12">
          <h1>Ops, algo saiu errado!</h1>
          <p>A página que você está procurando não foi encontrada :(</p>
          <a href="/" className="text-primary">
            Ir para o início
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
